var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d0fb4d6e9e5d90f1e6abe422867ae8c6acf22e52"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { Integration } from '@sentry/types';
import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';
import env from './env';

Sentry.init({
  dsn: env.sentry.dsn,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV || process.env.NODE_ENV,
  tracesSampleRate: 0.25,
  replaysSessionSampleRate: 0.3,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: false,
    })
  ] as Integration[],
});
